import ClassHeader from "./ClassHeader";
import VideoMenu from "./VideoMenu";
import ClassesMenu from "./ClassesMenu";
import React, { useContext } from "react";
import { MyProfileContext } from "./MyProfileContext";
import { Redirect } from "@reach/router";
import { contentTypes, useParameters } from "./constants";
import { kebabCase } from "lodash";
import Spinner from "../Common/Spinner";
import { useStripeCheckout } from "../../hooks/useStripeCheckout";

export const Spinny = ({ text = "Creating Order" }) => (
  <span className="mt-3 mb-6 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-white hover:bg-indigo-50 sm:w-auto">
    <svg
      className="animate-spin w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
        clipRule="evenodd"
      />
    </svg>
    &nbsp;
    {text}
  </span>
);

const BuyExtendedClassSection = ({ product }) => {
  const { loading, redirectToCheckout } = useStripeCheckout(product);
  return (
    <div className="rounded-lg bg-white overflow-hidden shadow text-center">
      <div className="mt-4">Extended Class Available:</div>
      <div>Casting cleanup and polishing</div>
      {loading ? (
        <Spinny />
      ) : (
        <button
          onClick={redirectToCheckout}
          className="max-w-md mx-auto text-base text-white sm:text-lg mt-3 mb-6
        md:text-inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md bg-blue-700 hover:bg-blue-500 sm:w-auto"
        >
          Upgrade Now
        </button>
      )}
    </div>
  );
};

function LessonContent({
  classInfo,
  children,
  upgradeProduct,
  videoItems,
  loadingVideos,
}) {
  return (
    <div className="w-full bg-gray-100">
      <header className="pb-24 bg-gradient-to-r from-light-blue-800 to-cyan-600">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="relative flex flex-wrap items-center justify-center lg:justify-between">
            <div className="absolute left-0 py-5 flex-shrink-0 lg:static"></div>
          </div>
        </div>
      </header>
      <main className="-mt-24 pb-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="sr-only">Class</h1>
          <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-6">
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              <ClassHeader classItem={classInfo} />
              {children}
            </div>

            {/* Right column */}

            <div className="grid grid-cols-1 gap-4">
              {classInfo.upgradeable && upgradeProduct && (
                <BuyExtendedClassSection product={upgradeProduct} />
              )}
              <VideoMenu videos={videoItems} spinning={loadingVideos} />
              <ClassesMenu />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

const Lesson = ({ children }) => {
  const {
    items,
    bundle,
    classInfo,
    classProduct,
    videoItems,
    loadingVideos,
    hasClass,
  } = useContext(MyProfileContext).productValues;
  const { slug, type, lesson } = useParameters();
  const isClassItem = type === contentTypes.QS || type === contentTypes.BRIEF;
  const upgradeProduct = items.find(
    (item) =>
      item.metadata.type === "upgrade" &&
      item.metadata.base_class === classProduct?.id,
  );
  if (loadingVideos && hasClass) {
    return <Spinner />;
  }

  const videos = bundle ? bundle.videos : videoItems;
  if (!lesson && !isClassItem) {
    const videoTitle = videos[0] ? videos[0].title : "not-available";
    return (
      <Redirect
        to={`/app/my-profile/${slug}/${contentTypes.VIDEO}/${kebabCase(
          videoTitle,
        )}`}
        noThrow
      />
    );
  }

  return (
    <LessonContent
      classInfo={classInfo}
      children={children}
      upgradeProduct={upgradeProduct}
      videoItems={videos}
      loadingVideos={loadingVideos}
    />
  );
};
export default Lesson;
