import React, { createContext } from 'react'
import { useIndividualLessons } from './useIndividualLessons'

export const LessonStoreContext = createContext({lessons: []})

const LessonStoreContextProvider = ({ slug, children }) => {
  const lessons = useIndividualLessons()
  return <LessonStoreContext.Provider value={lessons}>
    {children}
  </LessonStoreContext.Provider>
}

export default LessonStoreContextProvider
