import { useEffect } from "react";

const date = new Date();

export const AffiliateCapturer = () => {
  useEffect(() => {
    const affiliateStorageItem = localStorage.getItem("affiliate");
    try {
      const affiliateInfo = JSON.parse(affiliateStorageItem);
      if (affiliateInfo) {
        if (new Date() > new Date(affiliateInfo.expiry)) {
          console.log(`Affiliate link expired. Removing affiliate ${affiliateInfo.id}`)
          localStorage.remove("affiliate");
        } else {
          console.log(
            `Affiliate info for affiliate ${affiliateInfo.id} found.`,
          );
        }
      } else {
        const params = new URLSearchParams(window.location.search);
        const wcaLink = params.get("wca");
        if (wcaLink) {
          localStorage.setItem(
            "affiliate",
            JSON.stringify({
              id: wcaLink,
              expiry: new Date(date.setMonth(date.getMonth() + 1)),
            }),
          );
        }
      }
    } catch (e) {}
  }, []);

  return null;
};
