import { API } from "aws-amplify";
import { useMyClasses } from "./useMyClasses";
import { slugify } from "../util";
import useSWR from "swr";
import kebabCase from "lodash/kebabCase";
import { getBundle } from "./useProduct";

const resourceapi = "resources";

const importAll = (r, cache) =>
  r.keys().forEach((key) => (cache[key] = r(key)));
const imageFiles = {};
importAll(
  require.context(`../static/posters/`, true, /\.(png|gif|jpg)$/),
  imageFiles,
);

const sortBy = (identifier) => (a, b) => {
  const nameA = a[identifier].toUpperCase();
  const nameB = b[identifier].toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const useVideoItems = (slug) => {
  const { items, bundles } = useMyClasses();
  const productDetails = items.filter((it) => slugify(it.name) === slug);
  const classProduct = productDetails.find((it) =>
    ["class", "extended_class", "bundle"].includes(it.metadata.type),
  );
  const getResources = async () => {
    const response = await API.get(
      resourceapi,
      `/resources/videos/${classProduct.id}`,
    );
    const videos = response.videos;
    videos.sort(sortBy("title"));
    return { videos: videos || [] };
  };
  const bundle = getBundle(bundles, slug);
  const { data } = useSWR(
    `/resources/videos/${classProduct ? classProduct.id : "empty"}`,
    getResources,
  );
  const standardVideos = data
    ? { videoItems: data.videos, loadingVideos: false }
    : { videoItems: [], loadingVideos: true };
  return bundle
    ? { videoItems: bundle.videos, loadingVideos: false }
    : standardVideos;
};

export const useVideo = (slug, lesson) => {
  const { videoItems } = useVideoItems(slug);
  const video = videoItems.find((it) => kebabCase(it.title) === lesson);
  const poster = video ? `./${video.poster}` : "";
  return {
    video,
    poster: imageFiles[poster] ? imageFiles[poster].default : "",
  };
};
