import { useCallback, useState } from "react";
import { API } from "aws-amplify";
import { stripe_public_key } from "../keys";
import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
try {
  stripePromise = loadStripe(stripe_public_key);
} catch (e) {
  console.error("Failed to establish connection to payment provider.");
}

export function useStripeCheckout(product, extraBodyArgs) {
  const [loading, setLoading] = useState(false);
  const redirectToCheckout = useCallback(async () => {
    if (!stripePromise) {
      throw new Error("Stripe not initialized. Unable to handle payments.");
    }
    const affiliateJson = window.localStorage.getItem("affiliate");
    const aff = JSON.parse(affiliateJson);
    setLoading(true);
    const stripe = await stripePromise;
    const fetchSession = async () => {
      const apiName = "stripecheckout";
      const apiEndpoint = "/subscribe";
      const body = {
        quantity: 1,
        client_reference_id: "item",
        product_id: product.id,
        metadata: {
          affiliate: aff ? aff.id : undefined,
        },
        ...(extraBodyArgs ? extraBodyArgs : {}),
      };
      return await API.post(apiName, apiEndpoint, { body });
    };

    const session = await fetchSession();

    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error);
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
    setLoading(false);
  }, [product, extraBodyArgs]);
  return { loading, redirectToCheckout };
}
