import React, { createContext, useCallback, useContext, useState } from 'react'
import {useSessionStorage} from 'react-use';

export const ClassStoreContext = createContext({
  classesInCart: [],
  putClassIntoCart: () => {},
  cartOpen: false,
  openCart: () => {}
})

const ClassStoreContextProvider = ({ children }) => {
  const [selectedClasses, setSelectedClasses] = useSessionStorage('class-shopping-cart',[])
  const [slideOutOpen, setSlideOutOpen] = useState(false)
  const selectClass = useCallback((id) => {
    if (selectedClasses.includes(id)) {
      setSelectedClasses(selectedClasses.filter(it => it !== id))
    } else {
      setSelectedClasses([...selectedClasses, id])
    }
  }, [selectedClasses, setSelectedClasses])

  return <ClassStoreContext.Provider value={{
    classesInCart: selectedClasses,
    putClassIntoCart: selectClass,
    cartOpen: slideOutOpen,
    openCart: setSlideOutOpen
  }}>
    {children}
  </ClassStoreContext.Provider>
}

export const useClassStoreContext = () => {
  const { classesInCart, putClassIntoCart, openCart, cartOpen } = useContext(ClassStoreContext)
  return {
    classesInCart, putClassIntoCart, cartOpen, openCart
  }
}

export default ClassStoreContextProvider
