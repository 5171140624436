import { useMyClasses } from "./useMyClasses";
import React, { useEffect, useMemo, useState } from "react";
import { overview, classes2022 } from "../content/classes";
import { slugify } from "../util";
import { API } from "aws-amplify";
import { useVideoItems } from "./useVideoItems";

const commsApi = "comms";

export function getBundle(bundles, slug) {
  const s = (Object.entries(bundles) ?? []).find(([k]) => slugify(k) === slug);
  return s && s.length > 1 ? s[1] : undefined;
}

export const useProduct = (slug) => {
  const { spinning, items, bundles } = useMyClasses();
  const { loadingVideos, videoItems } = useVideoItems(slug);
  const [hours, setHours] = useState([]);

  const brieflessClasses = classes2022.map((it) => ({
    ...it,
    withoutClientBrief: true,
  }));
  const classInfo = [...overview.classes, ...brieflessClasses].find(
    (it) =>
      slug.startsWith(slugify(it.timeframe)) ||
      slugify(it.title) === slug ||
      slugify(it.identifier) === slug ||
      (it.additionalTitles &&
        it.additionalTitles.some((t) => slugify(t) === slug))
  );
  const productDetails = useMemo(
    () => items.filter((it) => slugify(it.name) === slug),
    [items, slug]
  );
  const classProduct = productDetails.find(
    (it) =>
      it.metadata.type === "class" || it.metadata.type === "extended_class"
  );
  const briefProduct = productDetails.find(
    (it) => it.metadata.type === "brief"
  );

  useEffect(() => {
    const getQuestionHours = async () => {
      const response = await API.get(commsApi, `/comms/question-hours/${slug}`);
      const questionHours = response?.qs?.hours || [];
      setHours(questionHours.map((it) => ({ ...it, start: it.date })) || []);
    };
    getQuestionHours();
  }, [slug]);

  if (!productDetails.length === 0) {
    return <div>No items found.</div>;
  }
  const hasClass = !!classProduct;
  const bundle = getBundle(bundles, slug);
  return {
    items,
    bundle,
    classInfo,
    spinning,
    hasClass,
    loadingVideos: bundle ? spinning : loadingVideos,
    videoItems,
    classProduct,
    briefProduct,
    hours,
  };
};
