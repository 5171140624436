import { contentTypes, useParameters } from './constants'
import { Link, useLocation } from '@reach/router'
import React, { useContext, useEffect } from 'react'
import { MyProfileContext } from './MyProfileContext'
import { useVideo } from '../../hooks/useVideoItems'

const ClassHeader = ({ classItem = { images: [] } }) => {
  const { slug, type, lesson } = useParameters()
  const loc = useLocation()
  const { previousLesson, setPreviousLesson, productValues } = useContext(MyProfileContext)
  const activeClass = (contentType) => `${loc.pathname.endsWith(`${contentType}/`) || loc.pathname.endsWith(contentType) ? 'text-gray-600 font-bold underline' : ''}`
  const lessonSlug = lesson || previousLesson
  const classSlug = slug
  useEffect(() => {
    setPreviousLesson(lessonSlug)
  }, [setPreviousLesson, lessonSlug])
  const { video } = useVideo(classSlug, lessonSlug)
  return <section
    aria-labelledby="profile-overview-title">
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <h2 className="sr-only" id="profile-overview-title">Class Overview</h2>
      <div className="bg-white p-6">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="flex-shrink-0">
              <img className="mx-auto h-20 w-20 rounded-full"
                   src={classItem && classItem.image}
                   alt="" />
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-xl font-bold text-gray-900 sm:text-2xl">{classItem.title}</p>
              <p className="text-sm font-medium text-gray-600">{video ? video.title : ''}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
        <Link to={`/app/my-profile/${classSlug}/${contentTypes.VIDEO}/${lessonSlug}`}
              className={`px-6 py-5 text-sm font-medium text-center cursor-pointer ${activeClass(contentTypes.VIDEO)}`}>
          <span>Video</span>
        </Link>

        <Link to={`/app/my-profile/${classSlug}/${contentTypes.HANDOUT}/${lessonSlug}`}
              className={`px-6 py-5 text-sm font-medium text-center cursor-pointer ${activeClass(contentTypes.HANDOUT)}`}>
          <span>Lesson Handout</span>
        </Link>
        <Link to={`/app/my-profile/${classSlug}/${contentTypes.QS}`}
              className="px-6 py-5 text-sm font-medium text-center cursor-pointer">
        <span
          className={activeClass(contentTypes.QS)}>Questions/Comments</span>
        </Link>
      </div>
    </div>
  </section>
}

export default ClassHeader
