import React from "react";
import Navigation from "./Router/Navigation";
import Footer from "./Router/Footer";
import { Helmet } from "react-helmet";
import { ClassShoppingCartWrapper } from "./Shop/ClassShoppingCartWrapper";
import { Banner } from "./Layout/Banner";

const Layout = ({
  children,
  full,
  title = "Wax Carvers",
  description = "Do you want to learn wax carving? You've come to the right place! Here you can find online wax carving classes and learn to make beautiful and fun jewellery.",
}) => {
  return (
    <>
      <div className={` ${full ? "" : "mx-auto container"}`}>
        <div className="min-h-screen bg-white">
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
          >
            <meta
              content="width=device-width,initial-scale=1"
              name="viewport"
            />
            <meta content="#000000" name="theme-color" />
            <meta
              content="text/html; charset=utf-8"
              http-equiv="Content-Type"
            />
            <title>Wax Carvers - Online Wax Carving Classes</title>
            <meta content={title} itemProp="name" />
            <meta content={description} itemProp="description" />
            <meta
              content="http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png"
              itemProp="image"
            />
            <meta
              content="http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png"
              name="twitter:card"
            />
            <meta content={title} name="twitter:title" />
            <meta content={description} name="twitter:description" />
            <meta
              content="http://images.waxcarvers.com.s3-website-eu-west-1.amazonaws.com/waxcarvers.png"
              name="twitter:image:src"
            />
            <meta content="3607036156001185" property="fb:admins" />
            <meta content={description} name="description" />
          </Helmet>
          <ClassShoppingCartWrapper>
            <Banner />
            <Navigation />
            {children}
          </ClassShoppingCartWrapper>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
