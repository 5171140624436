import React from 'react';
import { Link, useParams } from '@reach/router'
import kebabCase from 'lodash/kebabCase'
import Spinner from '../Common/Spinner'

const importAll = (r, cache) => r.keys().forEach(
  (key) => cache[key] = r(key)
)
const imageFiles = {}
importAll(require.context(`../../static/posters/`, true, /\.(png|gif|jpg)$/), imageFiles)
const ListItem = ({ video }) => {
  const params = useParams()
  const poster = `./${video.poster}`
  const link = `/app/my-profile/${params.slug}/video/${kebabCase(video.title)}`
  return <li className="py-5 flex">
    <div className="flex-shrink-0">
      <img className="h-8 w-8 mr-3 rounded-full"
           src={imageFiles[poster] ? imageFiles[poster].default : ''}
           alt="" />
    </div>
    <Link to={link}
          className="hover:underline focus:outline-none">
      <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
        <h3 className="text-sm font-semibold text-gray-800">
          <span className="absolute inset-0" aria-hidden="true"></span>
          {video.title}
        </h3>
        <p className="mt-1 text-sm text-gray-600 line-clamp-2">
          {video.description}
        </p>
      </div>
    </Link>
  </li>
}

const VideoMenu = ({ videos, spinning }) => {
  return <section aria-labelledby="announcements-title">
    <div className="rounded-lg bg-white overflow-hidden shadow">
      <div className="p-6">
        <h2 className="text-base font-medium text-gray-900" id="announcements-title">Lessons</h2>
        <div className="flow-root mt-6">
          {spinning
            ? <Spinner />

            : videos && videos.length > 0
              ? <ul className="-my-5 divide-y divide-gray-200">
                {videos.map(it => <ListItem video={it} key={it.id} />)}
              </ul>
              : <div className="relative focus-within:ring-2 focus-within:ring-cyan-500">
                <h3 className="text-sm font-semibold text-gray-800">
                  Nothing here yet. Check back later for new videos.
                </h3>
              </div>
          }
        </div>
      </div>
    </div>
  </section>
}

export default VideoMenu
