import { API } from 'aws-amplify'
import useSWR from 'swr'

const apiName = 'getStripeItems'
const HARDCODED_BUNDLE = 'advent-calendar-2022'
const apiEndpoint = `/products?bundleId=${HARDCODED_BUNDLE}`

export const useIndividualLessons = () => {
  const getItems = async () => {

    const fetchItems = async () => {

      return await API.get(apiName, apiEndpoint)
    }
    const items = await fetchItems()
    return { lessons: items.items }
  }
  const { data } = useSWR(`${apiName}/${apiEndpoint}`, getItems)
  return data
}
