import React, { createContext, useState } from "react";
import { useProduct } from "../../hooks/useProduct";

export const MyProfileContext = createContext({});

const MyProfileContextProvider = ({ slug, children }) => {
  const productValues = useProduct(slug);
  const [previousLesson, setPreviousLesson] = useState();
  return (
    <MyProfileContext.Provider
      value={{ productValues, previousLesson, setPreviousLesson }}
    >
      {children}
    </MyProfileContext.Provider>
  );
};

export default MyProfileContextProvider;
