import React from "react";

import { Link } from "gatsby";

export const Banner = () => (
  <div className="bg-blue-600">
    <div className="mx-auto max-w-7xl py-1 px-3 sm:px-6 lg:px-8">
      <div className="flex flex-wrap items-center justify-center">
        <div className="flex items-center mx-8 my-2">
          <span className="flex rounded-lg bg-blue-800 p-2 hidden md:inline">
            <svg
              className="h-6 w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>
          </span>
          <p className="ml-3 truncate font-medium text-white">
            <span className="md:hidden">Wax carving advent calendars?</span>
            <span className="hidden md:inline">
              Wax carving advent calendars?
            </span>
          </p>
        </div>
        <div className="order-3 mt-2 w-full sm:order-2 sm:mt-0 sm:w-auto"></div>
        <Link
          to="/advent-calendar"
          className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2
                 text-sm font-medium text-blue-800 shadow-sm hover:bg-blue-50"
        >
          Interesting! Tell me more
        </Link>
      </div>
    </div>
  </div>
);
