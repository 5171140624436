import React from "react";
import { slugify } from "../../util";
import { Link } from "@reach/router";
import { useMyClasses } from "../../hooks/useMyClasses";

const ListItem = ({ classItem }) => {
  return (
    <li className="py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <img
            className="h-8 w-8 rounded-full"
            src={classItem && classItem.images[0]}
            alt=""
          />
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">
            {classItem.description}
          </p>
          <p className="text-sm text-gray-500 truncate">{classItem.name}</p>
        </div>
        <div>
          <Link
            to={`/app/my-profile/${slugify(classItem.name)}/lesson`}
            className="inline-flex items-center shadow-sm px-3 py-1 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
          >
            View
          </Link>
        </div>
      </div>
    </li>
  );
};
const ClassesMenu = () => {
  const { items } = useMyClasses();
  const reducedItems = Object.values(
    items.reduce((acc, it) => {
      if (!acc[slugify(it.name)] && it.metadata.type !== "upgrade") {
        acc[slugify(it.name)] = it;
      }
      return acc;
    }, {}),
  );
  return (
    <section aria-labelledby="my-classes-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="p-6">
          <h2
            className="text-base font-medium text-gray-900"
            id="my-classes-title"
          >
            My Classes
          </h2>
          <div className="flow-root mt-6">
            <ul className="-my-5 divide-y divide-gray-200">
              {reducedItems.map((it) => (
                <ListItem classItem={it} key={it.id} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ClassesMenu;
