import { API } from 'aws-amplify'
import { useContext } from 'react'
import { AppContext } from '../context'
import useSWR from 'swr'

const resourceapi = 'resources'

export const useBundles = (bundle) => {
  const { data } = useSWR(`${resourceapi}/resources/bundle/${bundle}`, async () => {
    const response = await API.get(resourceapi, `/resources/bundle/${bundle}`)
    return response.videos || []
  })
  return { spinning: !(!!data), items: data }
}

