import { API } from "aws-amplify";
import { useContext } from "react";
import { AppContext } from "../context";
import useSWR from "swr";
import { useBundles } from "./useBundles";
import { classes2022 } from "../content/classes";

const resourceapi = "resources";

export const useMyClasses = () => {
  const { state: contextState } = useContext(AppContext);
  const { items: adventCalendarBundleItems } = useBundles(
    "advent-calendar-2022",
  );
  const { items: advent2023CalendarBundleItems } = useBundles(
    "advent-calendar-2023",
  );
  const { data } = useSWR(`${resourceapi}/resources`, async () => {
    const response = await API.get(resourceapi, "/resources");
    return { items: response.products || [] };
  });
  const { products } = contextState;

  const currentItems = data
    ? products
        .filter(
          (it) => data.items.includes(it.id) || it.metadata.type === "upgrade",
        )
        .filter((it) => it.metadata.type !== "bundle")
        .filter((it) => it.metadata.type !== "bundle_item")
    : [];
  const hasItemsFromAdventCalendarBundle = Boolean(adventCalendarBundleItems);
  const hasItemsFrom2023AdventCalendarBundle = Boolean(
    advent2023CalendarBundleItems,
  );
  return {
    spinning: !!!data,
    items: currentItems,
    bundles: {
      ...(hasItemsFromAdventCalendarBundle
        ? {
            "advent-calendar-2022": {
              videos: adventCalendarBundleItems,
              ...classes2022.find(
                (cls) => cls.identifier === "advent-calendar-2022",
              ),
            },
          }
        : {}),
      ...(hasItemsFrom2023AdventCalendarBundle
        ? {
            "advent-calendar-2023": {
              videos: advent2023CalendarBundleItems,
              ...classes2022.find(
                (cls) => cls.identifier === "advent-calendar-2023",
              ),
            },
          }
        : {}),
    },
  };
};
