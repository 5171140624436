import React, { useContext } from "react";
import { Transition } from "@headlessui/react";
import { LessonStoreContext } from "../Shop/LessonStoreContext";
import { useStripeCheckout } from "../../hooks/useStripeCheckout";
import { Spinny } from "../MyProfile/Lesson";

const HARDCODED_LESSON_PRICE = 10;

function Divider() {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-start">
        <span className="bg-white pr-2 text-sm text-gray-500">Total</span>
      </div>
    </div>
  );
}

export const SlideOut = ({
  isOpen,
  setOpen,
  selectedLessons,
  selectLesson,
}) => {
  const ctx = useContext(LessonStoreContext);
  const lessons = ctx?.lessons ?? [];
  const { loading, redirectToCheckout } = useStripeCheckout(
    {},
    { product_ids: selectedLessons.join(",") }
  );
  return (
    <Transition
      show={isOpen}
      enter="transform transition ease-in-out duration-500 sm:duration-700"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-500 sm:duration-700"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      {(ref) => {
        const hasLessons = selectedLessons.length > 0;
        return (
          <div
            className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 z-50"
            ref={ref}
          >
            <div className="pointer-events-auto w-screen max-w-md">
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2
                      className="text-lg font-medium text-gray-900"
                      id="slide-over-title"
                    >
                      Shopping Cart
                    </h2>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">Close panel</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <div className="absolute inset-0 px-4 sm:px-6">
                    <div>
                      <div className="mt-6 flow-root">
                        <ul
                          role="list"
                          className="-my-5 divide-y divide-gray-200"
                        >
                          {hasLessons ? (
                            selectedLessons.map((id) => {
                              const lesson = lessons.find((it) => it.id === id);
                              return (
                                <li className="py-4" key={lesson.name}>
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <img
                                        className="h-8 w-8 rounded-full"
                                        src={lesson.images[0]}
                                        alt="Image of the lesson"
                                      />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <p className="truncate text-sm font-medium text-gray-900">
                                        {lesson.name}
                                      </p>
                                      <p className="truncate text-sm text-gray-500">
                                        {lesson.description}
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        type="button"
                                        onClick={() => selectLesson(id)}
                                        className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50"
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <li className="py-4">
                              Nothing here yet. Select lessons from the table to
                              purchase.
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="mt-6">
                        {hasLessons && (
                          <>
                            <Divider />
                            <div className="min-w-0 flex-1 mb-8">
                              <p className="truncate text-sm font-medium text-gray-900">
                                Total:{" "}
                                {selectedLessons.length *
                                  HARDCODED_LESSON_PRICE}
                                €
                              </p>
                            </div>
                            {loading ? (
                              <Spinny />
                            ) : (
                              <button
                                onClick={redirectToCheckout}
                                className="inline-flex items-center justify-center rounded-md border border-transparent
          bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none
           focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                              >
                                Looks Good! Checkout
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Transition>
  );
};
