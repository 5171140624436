import { useMatch } from '@reach/router'

export const contentTypes = {
  VIDEO: 'video',
  HANDOUT: 'handout',
  QS: 'question-hours',
  BRIEF: 'client-brief'
}

export const matchPath = '/app/my-profile/:slug/:type/*'
export const classMatchPath = '/app/my-profile/:slug/*'

export const useParameters = () => {
  const { slug } = useMatch(classMatchPath)
  const match = useMatch(matchPath)
  const { type, '*': lesson } = match ? match : {}
  return { slug, type, lesson }
}

