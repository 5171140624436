import words from "lodash/words";
import kebabCase from "lodash/kebabCase";

export function slugify(productName) {
  return kebabCase(productName);
  /*const splat = words(productName.trim().toLowerCase())
    if(splat.length === 1){
        return splat[0]
    }
    return `${splat[0]}-${splat[1][2]}${splat[1][3]}`;*/
}
