import React from 'react'
import { SlideOut } from '../Common/SlideOut'
import ClassStoreContext, { useClassStoreContext } from './ClassStoreContext'

const Cart = () => {
  const { classesInCart, putClassIntoCart, cartOpen, openCart } = useClassStoreContext()
  return (<>
    <SlideOut isOpen={cartOpen}
              setOpen={openCart}
              selectLesson={putClassIntoCart}
              selectedLessons={classesInCart} />

  </>)
}

export const ClassShoppingCartWrapper = ({ children }) =>
  <ClassStoreContext>
    <Cart />
    <div>
      {children}
    </div>
  </ClassStoreContext>

